.tile-box {
  border-style: solid;
  border-width: 2px;
}

.tile-box-shortcut .tile-content-wrapper {
  padding: 0;
  min-height: 80px;
}

.tile-box-shortcut .tile-header {
  color: #fcfcfc;
  background: 0 0;
  padding: 0;
  text-transform: capitalize;
  position: absolute;
  right: 10px;
  bottom: 6px;
  font-size: 20px;
  font-weight: bolder;
}

.tile-box-shortcut .tile-content-wrapper > .fa {
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 3rem;
  color: #fcfcfc;
  opacity: 0.3;
}

.card {
  margin-bottom: 1rem;
}

.btn-barcode {
  background-color: #dd4f24;
  border-color: #cd3f14;
}

.btn-truck {
  background-color: #dd8f24;
  border-color: #cd7f14;
}

.btn-faces {
  background-color: #1abc9c;
  border-color: #0aac8c;
}
.btn-report {
  background-color: #1a8c9c;
  border-color: #0a7c8c;
}

.btn-cnh {
  background-color: #34495e;
  border-color: #24394e;
}

.btn-ocr {
  background-color: #2980b9;
  border-color: #1970a9;
}
