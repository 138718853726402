.panel-container {
  margin: 1rem 0rem 1rem 0rem;
  border-width: 2px;
  border-style: dashed;
  border-color: rgb(207, 212, 219);
  border-radius: 3px;
  min-width: 100%;
  min-height: 300px;
}

.panel-box {
  display: flex;
  align-items: center;
  flex-flow: column;
  text-align: center;
}

.center {
  align-self: center;
  justify-content: center;
  text-align: center;
}

.view-container {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem 1rem 0rem;
  min-width: 100%;
  min-height: 300px;
  height: 100%;
  justify-content: center;
}

[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

canvas {
  width: 100%;
  object-fit: scale-down;
}

.button-header {
  padding-bottom: 1rem;
}

.message-box {
  padding: 1rem;
}

.icon-image {
  margin: 0 auto;
}
