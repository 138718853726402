.login-container {
  color: rgb(85, 85, 85);
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-height: 100vh;
  overflow-y: auto;
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.login-container .col {
  padding: 0 10%;
  min-width: 400px;
  max-width: 400px;
}

.logo-text {
  color: rgb(0, 0, 0);
  padding-bottom: 0.85rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: bolder;
}

.logo-image {
  text-align: center;
  padding-bottom: 2rem;
}
